import classNames from 'classnames';
import styles from './processBoxNew.module.scss';

export const ProcessBoxNew = ({ index, title, text }) => {
  return (
    <div className={styles.item}>
      <span className={styles.index}>{index}</span>
      <div className={styles.textWrap}>
        <h3 className={styles.title}>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};
export const ProcessBoxListNew = ({ list, size }) => {
  return (
    <ol className={classNames(styles.list, styles[`list-size-${size}`])}>
      {list.map((box, i) => {
        return (
          <li className={styles.listItem} key={i}>
            <ProcessBoxNew {...box} />
          </li>
        );
      })}
    </ol>
  );
};
