import { FC } from 'react';
import type { ImageField } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import styles from './KeyHighlight.module.scss';

interface KeyHighlightProps {
  image: ImageField;
  title?: string;
  text?: string;
}

export const KeyHighlight: FC<KeyHighlightProps> = ({ image, title, text }) => {
  return (
    <div className={styles['list-item']}>
      <div className={styles['list-item-icon']}>
        <PrismicNextImage field={image} width={48} height={48} />
      </div>
      <div className={styles['list-item-info']}>
        {title && <h3 className={styles['list-item-title']}>{title}</h3>}
        {text && <div className={styles['list-item-text']}>{text}</div>}
      </div>
    </div>
  );
};
